import { MatDateFormats } from '@angular/material/core';

export const B360_FULLDATE_FORMAT: MatDateFormats = {
    parse: {
        dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
    },
    display: {
        dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
        monthYearLabel: 'MM/YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const B360_MONTH_AND_YEAR_FORMAT: MatDateFormats = {
    parse: {
        dateInput: 'MM/YYYY', // this is how your date will be parsed from Input
    },
    display: {
        dateInput: 'MM/YYYY', // this is how your date will get displayed on the Input
        monthYearLabel: 'MM/YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const B360_YEAR_ONLY_FORMAT: MatDateFormats = {
    parse: {
        dateInput: 'YYYY', // this is how your date will be parsed from Input
    },
    display: {
        dateInput: 'YYYY', // this is how your date will get displayed on the Input
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};
